import PropTypes from "prop-types";
import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";

import { ConfigContext } from "./contexts";
import { trackEvent } from "./utm_tracking";

const TextMarkdown = ({ source }) => {
    const { slug } = useContext(ConfigContext);

    const onClick = (href) => {
        trackEvent("click-link", {
            landingType: "amplify-landing",
            slug,
            action: href,
        });
    };

    const renderers = {
        // eslint-disable-next-line react/prop-types
        link: ({ href, children }) => {
            return (
                <a onClick={() => onClick(href)} href={href} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            );
        },
    };

    return <ReactMarkdown source={source} renderers={renderers} />;
};
TextMarkdown.propTypes = {
    source: PropTypes.string.isRequired,
};

export default TextMarkdown;
