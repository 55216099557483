import PropTypes from "prop-types";
import React, { useContext } from "react";

import { ConfigContext } from "../../contexts";
import { FilePlayer } from "./filePlayer";
import { YoutubePlayer, isYoutubeUrl } from "./youtubePlayer";

const VideoPanel = ({ videoUrls }) => {
    const { additionalConfig = {} } = useContext(ConfigContext);
    const { video: config = {} } = additionalConfig;

    const youtubeVideoId = isYoutubeUrl(videoUrls.video);
    if (youtubeVideoId) {
        return (
            <YoutubePlayer
                videoId={youtubeVideoId}
                autoPlay={config.autoPlay || false}
                loop={config.loop || false}
                soundIcon={config.soundIcon !== false}
            />
        );
    } else {
        return (
            <FilePlayer
                videoUrls={videoUrls}
                autoPlay={config.autoPlay || false}
                loop={config.loop || false}
                soundIcon={config.soundIcon !== false}
            />
        );
    }
};
export const VideoPropTypes = PropTypes.shape({
    video: PropTypes.string.isRequired,
    poster: PropTypes.string,
});
VideoPanel.propTypes = {
    videoUrls: VideoPropTypes.isRequired,
};

export default VideoPanel;
