import parsePhoneNumber from "libphonenumber-js";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import styled from "styled-components/macro";

import { ConfigContext, ThemeContext } from "../../contexts";
import { ReactComponent as BookOnlineIcon } from "../../images/contact-booking-50x50.svg";
import { ReactComponent as EmailIcon } from "../../images/contact-email-50x50.svg";
import { ReactComponent as FacebookIcon } from "../../images/contact-facebook-50x50.svg";
import { ReactComponent as InstagramIcon } from "../../images/contact-instagram-50x50.svg";
import { ReactComponent as LinkedinIcon } from "../../images/contact-linkedin-50x50.svg";
import { ReactComponent as MessengerIcon } from "../../images/contact-messenger-50x50.svg";
import { ReactComponent as PhoneIcon } from "../../images/contact-phone-50x50.svg";
import { ReactComponent as WebIcon } from "../../images/contact-web-50x50.svg";
import { ReactComponent as WhatsappIcon } from "../../images/contact-whatsapp-50x50.svg";
import { getColorMaxLightness } from "../../utils";
import { trackEvent } from "../../utm_tracking";
import MapIFrame from "./mapIFrame";

const MAX_LIGHTNESS = 70;

const StyledContactInfoPanel = styled.div`
    font-size: 17px;
    line-height: 1.4;

    a {
        color: ${(props) => props.color};
    }

    svg.icon {
        fill: ${(props) => props.color};
    }
`;

const StyledMap = styled.div`
    min-height: 200px;
    height: 30vmin;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
`;

const StyledLinks = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 13px;

    p {
        white-space: nowrap;
        margin-top: 5px;
        margin-bottom: 5px;

        i {
            display: inline-flex;
            vertical-align: middle;
            margin-right: 5px;
            svg {
                height: 25px;
            }
        }
    }
`;

const StyledLink = styled.a`
    width: 100%;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
    text-decoration: none;
`;

const addressFormat = (address) => {
    return address.replace(/,/g, "<br>");
};

export const urlCleanup = (url, removeDomain = "", removeTrailingSlash = false) => {
    let text = url;
    // Remove query parameters
    text = text.split("?")[0];
    // Remove http(s), www and optional removeDomain
    text = text.replace(new RegExp(`^(?:https?://)?(?:www.)?(?:${removeDomain}/)?`, "i"), "");
    if (
        text.substr(-1) === "/" &&
        (removeTrailingSlash ||
            // Remove the trailing slash if there is only one, ie test.com/
            (text.match(/\//g) || []).length === 1)
    ) {
        return text.substr(0, text.length - 1);
    }
    return text;
};

export const parsePhone = (text) => {
    const phone = parsePhoneNumber(text);
    if (!phone) {
        throw new Error(`Unable to parse phone number '${text}'`);
    }
    return {
        international: phone.formatInternational(),
        uri: phone.format("RFC3966"),
    };
};

const onClick = (slug, name) => {
    return () => {
        trackEvent(`click-contact-info-${name}`, {
            landingType: "amplify-landing",
            slug,
            contactInfo: name,
        });
    };
};

const Link = ({ href, text, slug, trackEventName }) => (
    <StyledLink href={href} onClick={onClick(slug, trackEventName)} target="_blank" rel="noopener noreferrer">
        {text}
    </StyledLink>
);
Link.propTypes = {
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    trackEventName: PropTypes.string.isRequired,
};

const ContactInfoPanel = ({ contactInfo }) => {
    const { slug } = useContext(ConfigContext);
    const { primaryColor } = useContext(ThemeContext);

    const phone = contactInfo.phone ? parsePhone(contactInfo.phone) : null;

    return (
        <StyledContactInfoPanel color={getColorMaxLightness(primaryColor, MAX_LIGHTNESS)}>
            {contactInfo.location && (
                <>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: addressFormat(contactInfo.location),
                        }}
                    />
                    <StyledMap id="location">
                        <MapIFrame address={contactInfo.location} width="100%" height="100%" />
                    </StyledMap>
                </>
            )}
            <StyledLinks>
                {phone && (
                    <div>
                        <p>
                            <i>
                                <PhoneIcon className={"icon"} />
                            </i>
                            <Link href={phone.uri} text={phone.international} slug={slug} trackEventName={"phone"} />
                        </p>
                    </div>
                )}
                {contactInfo.email && (
                    <div>
                        <p>
                            <i>
                                <EmailIcon className={"icon"} />
                            </i>
                            <Link
                                href={`mailto:${contactInfo.email}`}
                                text={contactInfo.email}
                                slug={slug}
                                trackEventName={"email"}
                            />
                        </p>
                    </div>
                )}
                {contactInfo.bookOnlineUrl && (
                    <div>
                        <p>
                            <i>
                                <BookOnlineIcon className={"icon"} />
                            </i>
                            <Link
                                href={contactInfo.bookOnlineUrl}
                                text={urlCleanup(contactInfo.bookOnlineUrl)}
                                slug={slug}
                                trackEventName={"bookOnlineUrl"}
                            />
                        </p>
                    </div>
                )}
                {contactInfo.webUrl && (
                    <div>
                        <p>
                            <i>
                                <WebIcon className={"icon"} />
                            </i>
                            <Link
                                href={contactInfo.webUrl}
                                text={urlCleanup(contactInfo.webUrl)}
                                slug={slug}
                                trackEventName={"webUrl"}
                            />
                        </p>
                    </div>
                )}
                {contactInfo.whatsappUrl && (
                    <div>
                        <p>
                            <i>
                                <WhatsappIcon className={"icon"} />
                            </i>
                            <Link
                                href={contactInfo.whatsappUrl}
                                text={urlCleanup(contactInfo.whatsappUrl)}
                                slug={slug}
                                trackEventName={"whatsappUrl"}
                            />
                        </p>
                    </div>
                )}
                {contactInfo.facebookUrl && (
                    <div>
                        <p>
                            <i>
                                <FacebookIcon className={"icon"} />
                            </i>
                            <Link
                                href={contactInfo.facebookUrl}
                                text={urlCleanup(contactInfo.facebookUrl, "facebook.com", true)}
                                slug={slug}
                                trackEventName={"facebookUrl"}
                            />
                        </p>
                    </div>
                )}
                {contactInfo.messengerUrl && (
                    <div>
                        <p>
                            <i>
                                <MessengerIcon className={"icon"} />
                            </i>
                            <Link
                                href={contactInfo.messengerUrl}
                                text={urlCleanup(contactInfo.messengerUrl)}
                                slug={slug}
                                trackEventName={"messengerUrl"}
                            />
                        </p>
                    </div>
                )}
                {contactInfo.instagramUrl && (
                    <div>
                        <p>
                            <i>
                                <InstagramIcon className={"icon"} />
                            </i>
                            <Link
                                href={contactInfo.instagramUrl}
                                text={urlCleanup(contactInfo.instagramUrl, "instagram.com", true)}
                                slug={slug}
                                trackEventName={"instagramUrl"}
                            />
                        </p>
                    </div>
                )}
                {contactInfo.linkedinUrl && (
                    <div>
                        <p>
                            <i>
                                <LinkedinIcon className={"icon"} />
                            </i>
                            <Link
                                href={contactInfo.linkedinUrl}
                                text={urlCleanup(contactInfo.linkedinUrl, "linkedin.com", true)}
                                slug={slug}
                                trackEventName={"linkedinUrl"}
                            />
                        </p>
                    </div>
                )}
            </StyledLinks>
        </StyledContactInfoPanel>
    );
};
export const ContactInfoPropTypes = PropTypes.shape({
    location: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    bookOnlineUrl: PropTypes.string,
    webUrl: PropTypes.string,
    whatsappUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    messengerUrl: PropTypes.string,
    instagramUrl: PropTypes.string,
    linkedinUrl: PropTypes.string,
});
ContactInfoPanel.propTypes = {
    contactInfo: ContactInfoPropTypes.isRequired,
};

export default ContactInfoPanel;
