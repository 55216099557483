import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components/macro";

import { initPolyglot } from "./i18n/i18n";
import { Background, CenterDiv, RootDiv } from "./landing";

const StyledTitle = styled.h1`
    margin-left: 20px;
    margin-right: 20px;
`;

class ErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        window.Rollbar.error("Landing rendering error", error, { errorInfo });
    }

    render() {
        if (this.state.hasError) {
            const locale = (window.navigator?.language || "en").split("-")[0];
            const polyglot = initPolyglot(locale);
            return (
                <>
                    <Background color="#e82323" />
                    <RootDiv>
                        <CenterDiv>
                            <StyledTitle>{polyglot.t("common.renderingError")}</StyledTitle>
                        </CenterDiv>
                    </RootDiv>
                </>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
