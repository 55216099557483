import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components/macro";

import { ConfigContext, ThemeContext } from "../../contexts";
import { ReactComponent as BookOnlineIcon } from "../../images/button-calendar.svg";
import { ReactComponent as ContactMeIcon } from "../../images/button-contact.svg";
import { ReactComponent as EmailIcon } from "../../images/button-email.svg";
import { ReactComponent as FacebookIcon } from "../../images/button-facebook.svg";
import { ReactComponent as InstagramIcon } from "../../images/button-instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../images/button-linkedin.svg";
import { ReactComponent as PhoneIcon } from "../../images/button-phone.svg";
import { ReactComponent as UrlIcon } from "../../images/button-url.svg";
import { ReactComponent as WebIcon } from "../../images/button-web.svg";
import { ReactComponent as MessengerIcon } from "../../images/contact-messenger-50x50.svg";
import { ReactComponent as WhatsappIcon } from "../../images/contact-whatsapp-50x50.svg";
import { getColorMaxLightness } from "../../utils";
import { trackEvent } from "../../utm_tracking";
import { ContactMe, useContactMeModal } from "../contact-me/modal";

const MAX_LIGHTNESS = 70;

const ActionType = Object.freeze({
    URL: "url",
    CONTACT_ME: "contact_me",
    PHONE: "phone",
    EMAIL: "email",
});

const IconName = Object.freeze({
    book_online: BookOnlineIcon,
});

const Icon = styled.i`
    display: inline-flex;
    vertical-align: middle;
    justify-items: center;
    align-items: center;
`;

const ActionButton = styled.a`
    color: ${(props) => (props.isHighlighted ? "white" : props.color)};
    background-color: ${(props) => (props.isHighlighted ? props.color : "none")};
    border: ${(props) => (props.isHighlighted ? "none" : `2px solid ${props.color}`)};
    border-radius: 30px;
    padding: 15px 5px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;

    &:hover,
    &:focus {
        outline: none;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    svg.icon {
        height: 25px;
        margin-right: 5px;
        fill: ${(props) => (props.isHighlighted ? "white" : props.color)};
    }
`;

const computeAction = (action) => {
    const { type, url, phone, email, iconName } = action;
    let icon;
    let href;
    switch (type) {
        case ActionType.URL:
            href = url;
            if (url.startsWith("tel:")) {
                icon = PhoneIcon;
            } else if (url.startsWith("mailto:")) {
                icon = EmailIcon;
            } else if (url.startsWith("https://facebook.com/")) {
                icon = FacebookIcon;
            } else if (url.startsWith("https://instagram.com/")) {
                icon = InstagramIcon;
            } else if (url.startsWith("https://linkedin.com/")) {
                icon = LinkedinIcon;
            } else if (url.startsWith("https://wa.me/")) {
                icon = WhatsappIcon;
            } else if (url.startsWith("https://m.me/")) {
                icon = MessengerIcon;
            } else if (url.startsWith("https://") || url.startsWith("http://")) {
                icon = WebIcon;
            } else {
                icon = UrlIcon;
            }
            break;
        case ActionType.PHONE:
            href = `tel:${phone}`;
            icon = PhoneIcon;
            break;
        case ActionType.EMAIL:
            href = `mailto:${email}`;
            icon = EmailIcon;
            break;
        case ActionType.CONTACT_ME:
            href = null;
            icon = ContactMeIcon;
            break;
        default:
            throw Error(`Unknown action type: '${type}'`);
    }
    if (iconName) {
        // Override icon
        icon = IconName[iconName];
        if (!icon) {
            throw Error(`Unable to find an icon for name: '${iconName}'`);
        }
    }
    return { href, icon: React.createElement(icon, { className: "icon" }) };
};

const StyledActionPanel = styled.div`
    margin-top: 12px;
`;

const ActionBarPanel = ({ actions }) => {
    const { slug, additionalConfig } = useContext(ConfigContext);
    const { primaryColor } = useContext(ThemeContext);
    const {
        isShowing: contactMeIsShowing,
        formName: contactMeFormName,
        title: contactMeTitle,
        config: contactMeConfig,
        show: contactMeShow,
        hide: contactMeHide,
        isConversion: contactMeIsConversion,
    } = useContactMeModal();

    return (
        <StyledActionPanel>
            <ContactMe
                isShowing={contactMeIsShowing}
                hide={contactMeHide}
                slug={slug}
                formName={contactMeFormName}
                title={contactMeTitle}
                config={contactMeConfig}
                isConversion={contactMeIsConversion}
            />
            <Row center="xs">
                {actions.map((action, index) => {
                    const { href, icon } = computeAction(action);
                    return (
                        <Col xs={12} key={index}>
                            <ActionButton
                                key={index}
                                href={href || "#"}
                                color={getColorMaxLightness(primaryColor, MAX_LIGHTNESS)}
                                title={action.description}
                                isHighlighted={index === 0}
                                onClick={() => {
                                    if (action.type === "contact_me") {
                                        contactMeShow(action.text, action.description, action.config, index === 0);
                                    } else if (index === 0) {
                                        // send conversion event
                                        trackEvent(
                                            "amplify-conversion",
                                            {
                                                landingType: "amplify-landing",
                                                slug,
                                                action: action.text,
                                            },
                                            null,
                                            additionalConfig?.conversionEvents
                                        );
                                    }
                                    const event = index === 0 ? "click-action-main" : "click-action";
                                    trackEvent(
                                        event,
                                        {
                                            landingType: "amplify-landing",
                                            slug,
                                            action: action.text,
                                        },
                                        action.text
                                    );
                                }}
                            >
                                <Icon>{icon}</Icon>
                                <span>{action.text}</span>
                                <span />
                            </ActionButton>
                        </Col>
                    );
                })}
            </Row>
        </StyledActionPanel>
    );
};
export const ActionBarPropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        type: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        url: PropTypes.string,
        config: PropTypes.shape({}),
    })
);
ActionBarPanel.propTypes = {
    actions: ActionBarPropTypes.isRequired,
};

export default ActionBarPanel;
