import { Col } from "react-styled-flexboxgrid";
import styled from "styled-components/macro";

export const PaddedCol = styled(Col)`
    @media (min-width: 48em) {
        padding-left: 5vw;
        padding-right: 5vw;
    }
`;

export const RightPaddedCol = styled(Col)`
    @media (min-width: 48em) {
        padding-left: 1vw;
        padding-right: 5vw;
    }
`;

export const LeftPaddedCol = styled(Col)`
    @media (min-width: 48em) {
        padding-left: 5vw;
        padding-right: 1vw;
    }
`;

export const TextSection = styled.h3`
    margin-bottom: 1em;
`;

export const StyledText = styled.div`
    font-size: 17px;
    line-height: 1.4;
    @media screen and (min-width: 768px) {
        margin-top: 30px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0.5em;
        margin-bottom: 0.7em;
    }

    > p {
        margin-top: 0;
    }
`;
