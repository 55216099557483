import colorsys from "colorsys";
import { useEffect } from "react";

export const getApiUrl = (path) => {
    return new URL(path, window.amplify.landingApiUrl);
};

export const getAssetPath = (url, slug, assetPath) => {
    return new URL(`${slug}/${assetPath}`, url).toString();
};

export const useKeyboardEvent = (key, callback) => {
    useEffect(() => {
        const handler = (event) => {
            if (event.key === key) {
                callback();
            }
        };
        window.addEventListener("keydown", handler);
        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, [callback, key]);
};

/** Override a static translation if the key is in a config object */
export const getTranslation = (key, translations, i18n) => {
    if (translations?.hasOwnProperty(key)) {
        return translations[key] ?? "";
    }
    return i18n(key);
};

export const getColorMaxLightness = (color, maxLightness) => {
    const hsl = colorsys.hex2Hsl(color);
    // Set maximum lightness
    hsl.l = Math.min(maxLightness, hsl.l);
    return colorsys.hsl2Hex(hsl);
};
