import Bowser from "bowser";
import PropTypes from "prop-types";
import React from "react";
import { Grid } from "react-styled-flexboxgrid";
import styled from "styled-components/macro";

import { ConfigContext, I18nContext, ThemeContext } from "./contexts";
import { initPolyglot } from "./i18n/i18n";
import background from "./images/background.svg";
import Education from "./templates/education";
import Generic from "./templates/generic";
import PhysicalBusiness from "./templates/physical-business";
import Restaurant from "./templates/restaurant";
import Service from "./templates/service";
import Shop from "./templates/shop";
import SocialNetworks from "./templates/social-networks";
import { trackEvent } from "./utm_tracking";

export const Background = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-color: ${(props) => props.color};
    mask-image: url(${background});
    mask-size: cover;

    @media (max-width: 48em) {
        visibility: hidden;
    }
`;

export const RootDiv = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;

    @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* Add your ie10-ie11 css here */

        // We have only 1 div in our flex layout, so a "row" flow should work but
        // ie11 have issue to align-items in this case
        // (https://github.com/philipwalton/flexbugs)
        flex-direction: column;
    }
`;

export const CenterDiv = styled.div`
    margin: auto;
    overflow-x: hidden;
    opacity: 0.95;

    > p {
        text-align: center;
        font-size: 13px;
        color: white;

        a {
            color: white;
        }

        @media (max-width: 48em) {
            display: none;
        }
    }
    @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* Add your ie10-ie11 css here */

        // Ie11 container's align-items: center is ignoring margin: auto
        // (https://github.com/philipwalton/flexbugs)
        align-self: stretch;
    }
`;

const LandingGrid = styled(Grid)`
    max-width: 1200px;
    background: white;

    @media (max-width: 48em) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @media (min-width: 48em) {
        margin: 2vh 4vw;
        border-radius: 15px;
    }
    padding: 20px 0;
`;

export const Landing = ({ descriptor, contentUrl, slug }) => {
    trackEvent("show-ad-landing-page", {
        landingType: "amplify-landing",
        slug,
        template: descriptor.template,
    });
    const polyglot = initPolyglot(descriptor.language || "en");

    const getTemplate = (name) => {
        switch (name) {
            case "generic":
                return (
                    <Generic
                        type={descriptor.type}
                        name={descriptor.name}
                        logoUrl={descriptor.assets.logoUrl}
                        description={descriptor.description}
                        imageUrls={descriptor.assets.imageCarouselUrls}
                        actions={descriptor.actions}
                        contactInfo={descriptor.contactInfo}
                    />
                );
            case "restaurant":
                return (
                    <Restaurant
                        type={descriptor.type}
                        name={descriptor.name}
                        logoUrl={descriptor.assets.logoUrl}
                        description={descriptor.description}
                        openingHours={descriptor.openingHours}
                        imageUrls={descriptor.assets.imageCarouselUrls}
                        actions={descriptor.actions}
                        contactInfo={descriptor.contactInfo}
                    />
                );
            case "shop":
                return (
                    <Shop
                        type={descriptor.type}
                        name={descriptor.name}
                        logoUrl={descriptor.assets.logoUrl}
                        description={descriptor.description}
                        openingHours={descriptor.openingHours}
                        videoUrls={descriptor.assets.videoUrls}
                        imageUrls={descriptor.assets.imageCarouselUrls}
                        actions={descriptor.actions}
                        contactInfo={descriptor.contactInfo}
                    />
                );
            case "service":
                return (
                    <Service
                        type={descriptor.type}
                        name={descriptor.name}
                        logoUrl={descriptor.assets.logoUrl}
                        description={descriptor.description}
                        videoUrls={descriptor.assets.videoUrls}
                        imageUrls={descriptor.assets.imageCarouselUrls}
                        actions={descriptor.actions}
                        contactInfo={descriptor.contactInfo}
                    />
                );
            case "education":
                return (
                    <Education
                        type={descriptor.type}
                        name={descriptor.name}
                        logoUrl={descriptor.assets.logoUrl}
                        description={descriptor.description}
                        videoUrls={descriptor.assets.videoUrls}
                        actions={descriptor.actions}
                        contactInfo={descriptor.contactInfo}
                    />
                );
            case "physical-business":
                return (
                    <PhysicalBusiness
                        type={descriptor.type}
                        name={descriptor.name}
                        logoUrl={descriptor.assets.logoUrl}
                        description={descriptor.description}
                        openingHours={descriptor.openingHours}
                        videoUrls={descriptor.assets.videoUrls}
                        imageUrls={descriptor.assets.imageCarouselUrls}
                        actions={descriptor.actions}
                        contactInfo={descriptor.contactInfo}
                    />
                );
            case "social-networks":
                return (
                    <SocialNetworks
                        type={descriptor.type}
                        name={descriptor.name}
                        logoUrl={descriptor.assets.logoUrl}
                        description={descriptor.description}
                        videoUrls={descriptor.assets.videoUrls}
                        imageUrls={descriptor.assets.imageCarouselUrls}
                        contactInfo={descriptor.contactInfo}
                    />
                );
            default:
                throw Error(`Unknown template "${name}"`);
        }
    };

    // Support old descriptor version
    if ((descriptor.version || 1) === 1) {
        // Manage deprecated image clipping default value
        const imageCarouselConfig = descriptor.imageCarousel || {};
        // Anything except false should return true
        imageCarouselConfig.clipping = imageCarouselConfig.clipping !== false;
        descriptor.imageCarousel = imageCarouselConfig;
        // Migrate deprecated configurations that were at descriptor's root
        descriptor.additionalConfig = {
            imageCarousel: descriptor.imageCarousel,
            video: descriptor.video,
            ...descriptor.additionalConfig,
        };
    }

    return (
        <ConfigContext.Provider
            value={{
                contentUrl: contentUrl,
                slug,
                owner: descriptor.owner,
                browser: Bowser.getParser(window.navigator.userAgent),
                additionalConfig: descriptor.additionalConfig,
            }}
        >
            <ThemeContext.Provider value={{ primaryColor: descriptor.primaryColor }}>
                <I18nContext.Provider
                    value={{
                        i18n: (phrase, args) => polyglot.t(phrase, args),
                        language: descriptor.language,
                    }}
                >
                    <Background color={descriptor.primaryColor} />
                    <RootDiv>
                        <CenterDiv>
                            <LandingGrid fluid={true}>{getTemplate(descriptor.template)}</LandingGrid>
                            <p>
                                Hosted by <a href="https://www.amplify.ooo">amplify.ooo</a>
                            </p>
                        </CenterDiv>
                    </RootDiv>
                </I18nContext.Provider>
            </ThemeContext.Provider>
        </ConfigContext.Provider>
    );
};
Landing.propTypes = {
    descriptor: PropTypes.object.isRequired,
    contentUrl: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
};

export default Landing;
