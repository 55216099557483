import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components/macro";

import { ConfigContext } from "../../contexts";
import { getFixedAspectRatioImagePath } from "../../image-transform";
import { getAssetPath } from "../../utils";

const ImagePanel = styled(Carousel)`
    img {
        object-fit: cover;

        ${(props) =>
            props.clipping &&
            `   
    min-height: 200px;
    height: 40vmin;
    max-height: 400px;`}
        ${(props) =>
            !props.clipping &&
            `   
    height: 100%;`}
    }

    .carousel.carousel-slider .control-arrow {
        opacity: 1;
    }
    .carousel.carousel-slider .control-arrow:hover {
        background: rgba(0, 0, 0, 0.4);
    }
`;

const ImageCarouselPanel = ({ imageUrls }) => {
    const { contentUrl, slug, additionalConfig = {} } = useContext(ConfigContext);
    const { imageCarousel: config = {} } = additionalConfig;
    return (
        <ImagePanel
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={6000}
            infiniteLoop={true}
            centerMode={!!config.centerMode}
            centerSlidePercentage={config.centerMode}
            clipping={config.clipping}
        >
            {imageUrls.map((value, index) => {
                const imageUrl = !config.clipping
                    ? getFixedAspectRatioImagePath(contentUrl, slug, value, config.aspectRatio || 2.5)
                    : getAssetPath(contentUrl, slug, value);
                return <img key={index} alt={value} src={imageUrl} />;
            })}
        </ImagePanel>
    );
};
export const ImageCarouselPropTypes = PropTypes.arrayOf(PropTypes.string);
ImageCarouselPanel.propTypes = {
    imageUrls: ImageCarouselPropTypes.isRequired,
};

export default ImageCarouselPanel;
