import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { ConfigContext, I18nContext } from "../contexts";
import ActionBarPanel, { ActionBarPropTypes } from "../panels/action-bar/panel";
import ContactInfoPanel, { ContactInfoPropTypes } from "../panels/contact-info/panel";
import HeaderPanel from "../panels/header/panel";
import ImageCarouselPanel, { ImageCarouselPropTypes } from "../panels/image-carousel/panel";
import VideoPanel, { VideoPropTypes } from "../panels/video/panel";
import { LeftPaddedCol, PaddedCol, RightPaddedCol, StyledText, TextSection } from "../styles/templates";
import TextMarkdown from "../text-markdown";
import { getTranslation } from "../utils";

export const Shop = ({
    type,
    name,
    logoUrl,
    description,
    openingHours,
    videoUrls,
    imageUrls,
    actions,
    contactInfo,
}) => {
    const { i18n } = useContext(I18nContext);
    const { additionalConfig: config } = useContext(ConfigContext);

    return (
        <Row>
            <PaddedCol xs={12}>
                <HeaderPanel name={name} type={type} logoUrl={logoUrl} />
            </PaddedCol>
            <Col xs={12}>
                {videoUrls ? <VideoPanel videoUrls={videoUrls} /> : <ImageCarouselPanel imageUrls={imageUrls} />}
            </Col>
            <PaddedCol xs={12} className={"mobile"}>
                <ActionBarPanel actions={actions} />
            </PaddedCol>
            <LeftPaddedCol xs={12} sm={7}>
                <StyledText>
                    <TextSection>{getTranslation("common.descriptionTitle", config?.translations, i18n)}</TextSection>
                    <TextMarkdown source={description} />
                    <TextSection>{getTranslation("common.openingHoursTitle", config?.translations, i18n)}</TextSection>
                    <TextMarkdown source={openingHours} />
                </StyledText>
            </LeftPaddedCol>
            <RightPaddedCol xs={12} sm={5}>
                <div className={"desktop"}>
                    <ActionBarPanel actions={actions} />
                </div>
                <TextSection>{getTranslation("contactInfo.title", config?.translations, i18n)}</TextSection>
                <ContactInfoPanel contactInfo={contactInfo} />
            </RightPaddedCol>
        </Row>
    );
};
Shop.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    description: PropTypes.string.isRequired,
    openingHours: PropTypes.string.isRequired,
    videoUrls: VideoPropTypes,
    imageUrls: ImageCarouselPropTypes,
    actions: ActionBarPropTypes.isRequired,
    contactInfo: ContactInfoPropTypes.isRequired,
};
Shop.defaultProps = {
    logoUrl: null,
    imageUrls: null,
    videoUrls: null,
};

export default Shop;
