import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import PoppinsItalic from "../fonts/Poppins/Poppins-Italic.ttf";
import PoppinsMedium from "../fonts/Poppins/Poppins-Medium.ttf";
import PoppinsRegular from "../fonts/Poppins/Poppins-Regular.ttf";

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  @font-face {
    font-family: Poppins;
    src: url(${PoppinsRegular});
    font-weight: 1 400;
  }
  @font-face {
    font-family: Poppins;
    src: url(${PoppinsMedium});
    font-weight: 401 700;
  }
  @font-face {
    font-family: Poppins;
    src: url(${PoppinsItalic});
    font-style: italic;
  }
 
  body {
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
    color: #312b43;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  svg.icon {
    fill: var(--main-text-color);
    width: 32px;
    height: 32px;
  }
  
  @media screen and (min-width: 768px) {
    .mobile {
      display: none !important;
    }
  }
  @media screen and (max-width: 767px) {
    .desktop {
      display: none !important;
    }
  }
`;
