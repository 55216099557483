import PropTypes from "prop-types";
import React from "react";

const MapIFrame = ({ address, height, width, zoom }) => {
    const q = encodeURI(address);
    const src = `https://maps.google.com/maps?q=${q}&z=${zoom}&ie=UTF8&output=embed`;
    return (
        <iframe
            title="contact-map"
            width={width}
            height={height}
            id="gmap_canvas"
            src={src}
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
        />
    );
};
MapIFrame.propTypes = {
    address: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    zoom: PropTypes.number,
};
MapIFrame.defaultProps = {
    zoom: 13,
};

export default MapIFrame;
