import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components/macro";

import ContactMeForm, { ContactMeConfirmation } from "./form";

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
`;

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    padding: 0 5px;
`;

const Content = styled.div`
    z-index: 100;
    background: white;
    position: relative;
    margin: 1.75rem auto;
    border-radius: 15px;
    max-width: 600px;
    padding: 2rem;
`;

const Title = styled.h2`
    text-align: center;
    margin-top: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdcdc;
`;

export const ContactMe = ({ isShowing, hide, slug, formName, title, config, isConversion }) => {
    const [isConfirmation, setConfirmation] = useState(false);
    return isShowing
        ? ReactDOM.createPortal(
              <>
                  <Overlay />
                  <Wrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
                      <Content>
                          <Title>{title || formName}</Title>
                          {isConfirmation ? (
                              <ContactMeConfirmation
                                  ok={() => {
                                      hide();
                                      setConfirmation(false);
                                  }}
                              />
                          ) : (
                              <ContactMeForm
                                  send={() => setConfirmation(true)}
                                  cancel={hide}
                                  slug={slug}
                                  formName={formName}
                                  config={config}
                                  isConversion={isConversion}
                              />
                          )}
                      </Content>
                  </Wrapper>
              </>,
              document.body
          )
        : null;
};
ContactMe.propTypes = {
    isShowing: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    formName: PropTypes.string,
    title: PropTypes.string,
    config: PropTypes.shape({}),
    isConversion: PropTypes.bool,
};
ContactMe.defaultProps = {
    formName: "",
    title: "",
    config: {},
    isConversion: false,
};

export const useContactMeModal = () => {
    const [allValues, setValues] = useState({
        isShowing: false,
        formName: "",
        title: "",
        config: {},
        isConversion: false,
    });

    const show = (formName, title, config, isConversion) =>
        setValues({ isShowing: true, formName, title, config, isConversion });
    const hide = () => setValues({ isShowing: false });

    return {
        isShowing: allValues.isShowing,
        formName: allValues.formName,
        title: allValues.title,
        config: allValues.config,
        isConversion: allValues.isConversion,
        show,
        hide,
    };
};
