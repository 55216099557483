import PropTypes from "prop-types";
import React, { useContext } from "react";
import styled from "styled-components/macro";

import { ConfigContext } from "../../contexts";
import { getAssetPath } from "../../utils";

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
        margin-top: 0px;
        margin-bottom: 5px;
    }
    h3 {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    img {
        height: 80px;
    }
    margin: 0 10px 10px;
`;

const HeaderPanel = ({ name, type, logoUrl }) => {
    const { contentUrl, slug } = useContext(ConfigContext);
    return (
        <Header>
            <div>
                <h1>{name}</h1>
                <h3>{type}</h3>
            </div>
            {logoUrl && <img src={getAssetPath(contentUrl, slug, logoUrl)} alt="logo" />}
        </Header>
    );
};
HeaderPanel.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
};
HeaderPanel.defaultProps = {
    logoUrl: null,
};

export default HeaderPanel;
