import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components/macro";

import { ConfigContext, I18nContext, ThemeContext } from "../../contexts";
import { useKeyboardEvent } from "../../utils";
import { trackEvent } from "../../utm_tracking";
import { postContactMe } from "./api-client";

const FormRow = styled(Row)`
    padding: 20px;
`;

const FormCol = styled(Col)`
    text-align: center;
`;

const Form = styled.form`
    font-size: 17px;

    p {
        color: #bf1650;

        ::before {
            display: inline;
            content: "⚠ ";
        }
    }
`;

const Fields = styled.div`
    label {
        display: block;
        line-height: 2;
        font-weight: 400;
        text-align: left;
        margin-bottom: 5px;
        margin-top: 20px;
    }

    input,
    textarea {
        display: block;
        width: 100%;
        border: 2px solid #a29eaa;
        border-radius: 10px;
        padding: 10px 15px;
        margin-bottom: 10px;

        :focus {
            outline: none;
            box-shadow: ${(props) => `0 0 10px ${props.inputFocusColor}`};
        }
    }

    textarea {
        resize: vertical;
    }
`;

const Consent = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 20px;

    input {
        margin-right: 12px;
    }

    label {
        font-size: 13px;
    }
`;

const Button = styled.button`
    color: ${(props) => (props.isHighlighted ? "white" : "#a29eaa")};
    background-color: ${(props) => (props.isHighlighted ? props.color : "white")};
    border: ${(props) => (props.isHighlighted ? "none" : `2px solid #a29eaa`)};
    border-radius: 30px;
    width: 100%;
    padding: 15px 5px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: 10px 0;

    &:hover,
    &:focus {
        outline: none;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    &:disabled {
        background-color: rgba(127, 127, 127, 0.4) !important;
    }
`;

const FormButtons = ({ cancel, isSendDisabled }) => {
    const { primaryColor } = useContext(ThemeContext);
    const { i18n } = useContext(I18nContext);

    return (
        <FormRow center="xs">
            <FormCol xs={12} sm={4}>
                <Button type="submit" color={primaryColor} isHighlighted={true} disabled={isSendDisabled}>
                    {i18n("contactMe.send")}
                </Button>
            </FormCol>
            <FormCol xs={12} sm={4}>
                <Button type="button" onClick={cancel} primaryColor="#aaa">
                    {i18n("contactMe.cancel")}
                </Button>
            </FormCol>
        </FormRow>
    );
};

FormButtons.propTypes = {
    cancel: PropTypes.func.isRequired,
    isSendDisabled: PropTypes.bool.isRequired,
};

const ContactMeForm = ({ send, cancel, slug, formName, config, isConversion }) => {
    const { owner = {}, additionalConfig } = useContext(ConfigContext);
    const { primaryColor } = useContext(ThemeContext);
    const { i18n, language } = useContext(I18nContext);
    const { register, handleSubmit, errors, setError } = useForm();
    const [isSendDisabled, setIsSendDisabled] = useState(false);

    useKeyboardEvent("Escape", () => {
        cancel();
    });

    const displayField = (name) => {
        return (config.fields || ["name", "email"]).includes(name);
    };

    const submit = async (data) => {
        setIsSendDisabled(true);
        const response = await postContactMe(
            slug,
            formName,
            data.name,
            data.email || "",
            data.phone || "",
            data.comment || "",
            owner,
            language
        );

        if (response.ok) {
            send();
            if (isConversion) {
                trackEvent(
                    "amplify-conversion",
                    {
                        landingType: "amplify-landing",
                        slug,
                        action: formName,
                    },
                    null,
                    additionalConfig?.conversionEvents
                );
            }
            trackEvent(
                "submit-action",
                {
                    landingType: "amplify-landing",
                    slug,
                    action: formName,
                },
                formName
            );
        } else {
            const error_data = await response.json();
            let errorHandled = false;
            if (error_data.detail) {
                for (const error of error_data.detail) {
                    if (error.type === "value_error.missing_contact") {
                        setError(error.loc[1], { type: "server" });
                        errorHandled = true;
                    }
                }
            }
            if (!errorHandled) {
                throw new Error(`postContactMe() -> status=${response.status}: ${JSON.stringify(error_data)}`);
            }
        }

        setIsSendDisabled(false);
    };

    return (
        <Form onSubmit={handleSubmit(submit)} inputFocusColor={primaryColor}>
            <Fields>
                {displayField("name") && (
                    <>
                        <label>{i18n("contactMe.name")}</label>
                        <input
                            type="text"
                            maxLength="50"
                            aria-invalid={errors.name ? "true" : "false"}
                            name="name"
                            ref={register}
                        />
                    </>
                )}
                {displayField("name") && errors.name && <p role="alert">{i18n("contactMe.fieldRequired")}</p>}

                {displayField("email") && (
                    <>
                        <label>{i18n("contactMe.email")}</label>
                        <input
                            type="email"
                            maxLength="200"
                            aria-invalid={errors.email ? "true" : "false"}
                            name="email"
                            ref={register}
                        />
                    </>
                )}
                {displayField("email") && errors.email && <p role="alert">{i18n("contactMe.fieldRequired")}</p>}

                {displayField("phone") && (
                    <>
                        <label>{i18n("contactMe.phone")}</label>
                        <input
                            type="tel"
                            maxLength="50"
                            aria-invalid={errors.phone ? "true" : "false"}
                            name="phone"
                            ref={register}
                        />
                    </>
                )}
                {displayField("phone") && errors.phone && <p role="alert">{i18n("contactMe.fieldRequired")}</p>}

                {displayField("comment") && (
                    <>
                        <label>{i18n("contactMe.comment")}</label>
                        <textarea
                            maxLength="1000"
                            rows="3"
                            aria-invalid={errors.comment ? "true" : "false"}
                            name="comment"
                            ref={register}
                        />
                    </>
                )}
                {displayField("comment") && errors.comment && <p role="alert">{i18n("contactMe.fieldRequired")}</p>}
            </Fields>

            <Consent>
                <label>{i18n("contactMe.consent", { owner: owner.company })}</label>
            </Consent>

            <FormButtons cancel={cancel} isSendDisabled={isSendDisabled} />
        </Form>
    );
};
ContactMeForm.propTypes = {
    send: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    isConversion: PropTypes.bool,
    config: PropTypes.shape({
        fields: PropTypes.arrayOf(PropTypes.string),
    }),
};
ContactMeForm.defaultProps = {
    config: {},
    isConversion: false,
};

export const ContactMeConfirmation = ({ ok }) => {
    const { primaryColor } = useContext(ThemeContext);
    const { i18n } = useContext(I18nContext);

    return (
        <>
            <FormRow center="xs">
                <FormCol xs={12}>{i18n("contactMe.confirmation")}</FormCol>
            </FormRow>
            <FormRow center="xs">
                <FormCol xs={12} sm={4}>
                    <Button type="submit" onClick={ok} color={primaryColor}>
                        {i18n("contactMe.ok")}
                    </Button>
                </FormCol>
            </FormRow>
        </>
    );
};
ContactMeConfirmation.propTypes = {
    ok: PropTypes.func.isRequired,
};

export default ContactMeForm;
