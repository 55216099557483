import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components/macro";

import { ConfigContext } from "../../contexts";
import { ReactComponent as PlayIcon } from "../../images/video-play-circle.svg";
import { ReactComponent as SoundIcon } from "../../images/video-sound-circle.svg";
import { getAssetPath } from "../../utils";

const Wrapper = styled.div`
    position: relative;
`;

const Video = styled.video`
    overflow: hidden;
    background: black;
    width: 100%;
    min-height: 200px; // min-height is important for ie11
    height: 40vmin;
    max-height: 400px;

    &:focus {
        outline: none;
    }
`;

const ControlOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        height: 40%;
        fill: white;
        opacity: 0.8;
        filter: drop-shadow(5px 5px 5px #444);
    }
`;

export class FilePlayer extends React.Component {
    static propTypes = {
        videoUrls: PropTypes.any.isRequired,
        autoPlay: PropTypes.bool.isRequired,
        loop: PropTypes.bool.isRequired,
        soundIcon: PropTypes.bool.isRequired,
    };
    static contextType = ConfigContext;

    videoRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = { showControlOverlay: true };
    }

    onControlClick = () => {
        const { autoPlay } = this.props;
        this.setState({
            showControlOverlay: false,
        });
        if (autoPlay) {
            this.videoRef.current.muted = false;
        } else {
            this.videoRef.current.play();
        }
    };

    render() {
        const { videoUrls, autoPlay, loop, soundIcon } = this.props;
        const { contentUrl, slug, browser } = this.context;

        let isOverlayEnabled;
        if (browser.satisfies({ desktop: { chrome: ">0" } })) {
            // Play icon only for Chrome desktop
            isOverlayEnabled = true;
        } else {
            isOverlayEnabled = autoPlay;
        }

        return (
            <Wrapper>
                <Video
                    ref={this.videoRef}
                    controls
                    playsInline
                    autoPlay={autoPlay}
                    muted={autoPlay}
                    loop={loop}
                    poster={getAssetPath(contentUrl, slug, videoUrls.poster)}
                >
                    <source src={getAssetPath(contentUrl, slug, videoUrls.video)} />
                </Video>
                {isOverlayEnabled && this.state.showControlOverlay && (
                    <ControlOverlay onClick={this.onControlClick}>
                        {autoPlay ? soundIcon && <SoundIcon /> : <PlayIcon />}
                    </ControlOverlay>
                )}
            </Wrapper>
        );
    }
}
