import { getCookie, setCookie } from "./cookies";
import { uuidv4 } from "./uuid";

const FACEBOOK_EVENTS_MAPPING = {
    Navigate: "PageView",
};

export function trackFacebookEvent(event, data) {
    const trackMethod = FACEBOOK_EVENTS_MAPPING[event] ? "track" : "trackCustom";
    const mappedEvent = FACEBOOK_EVENTS_MAPPING[event] || event;
    if (global.fbq) {
        global.fbq(trackMethod, mappedEvent, data);
    }
}

export function trackGoogleEvent(event, conversionEventId) {
    // We only send "conversion" event for now
    if (
        global.gtag &&
        event.startsWith("amplify-conversion") &&
        window.amplify.googleAdsConversionId &&
        conversionEventId
    ) {
        global.gtag("event", "conversion", {
            send_to: `${window.amplify.googleAdsConversionId}/${conversionEventId}`,
        });
    }
}

const DISTINCT_ID_COOKIE = "ampl_distinct_id";

let amplifyDistinctId = getCookie(DISTINCT_ID_COOKIE);
let amplifyUserId = null;

function createAmplifyUniqueId() {
    amplifyDistinctId = uuidv4();
    setCookie(DISTINCT_ID_COOKIE, amplifyDistinctId, 3650);
    return amplifyDistinctId;
}

if (!amplifyDistinctId) {
    amplifyDistinctId = createAmplifyUniqueId();
}

export function trackAmplifyEvent(event, data, amplifyGroupId) {
    if (window.amplify.amplifyAnalyticsEntryPoint) {
        const metric = {
            timestamp: `${new Date().toISOString().split(".")[0]}Z`,
            distinct_id: amplifyDistinctId,
            group_id: amplifyGroupId,
            event,
            properties: {
                currentUrl: window.location.href,
                userAgent: navigator.userAgent,
                ...data,
            },
        };
        if (amplifyUserId) {
            metric.user_id = amplifyUserId;
        }
        if (navigator.sendBeacon) {
            navigator.sendBeacon(window.amplify.amplifyAnalyticsEntryPoint, JSON.stringify(metric));
        }
    }
}
