import Polyglot from "node-polyglot";

import LocaleDe from "./de.json";
import LocaleEn from "./en.json";
import LocaleFr from "./fr.json";
import LocaleNl from "./nl.json";
import LocaleEsMx from "./es-mx.json";

export const getPhrases = (locale) => {
    switch (locale) {
        case "fr":
            return LocaleFr;
        case "de":
            return LocaleDe;
        case "nl":
            return LocaleNl;
        case "es-mx":
            return LocaleEsMx;
        default:
            return LocaleEn;
    }
};

export const initPolyglot = (locale) => {
    return new Polyglot({ locale: locale, phrases: getPhrases(locale) });
};
