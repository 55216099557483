import "core-js/es";
import "core-js/web";
import React from "react";
import ReactDOM from "react-dom";
// windows.fetch for ie11
import "whatwg-fetch";

import ErrorBoundary from "./error-boundary";
import Landing from "./landing";
import { GlobalStyle } from "./styles";

ReactDOM.render(
    <>
        <GlobalStyle />
        <React.StrictMode>
            <ErrorBoundary>
                <Landing
                    descriptor={window.amplify.landingDescriptor}
                    contentUrl={window.amplify.landingContentUrl}
                    slug={window.amplify.landingSlug}
                />
            </ErrorBoundary>
        </React.StrictMode>
    </>,
    document.getElementById("root")
);
