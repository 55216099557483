import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { ReactComponent as SoundIcon } from "../../images/video-sound-circle.svg";

export const isYoutubeUrl = (url) => {
    // https://github.com/regexhq/youtube-regex
    const match =
        /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/.exec(url);
    if (match) {
        return match[1];
    }
};

const Wrapper = styled.div`
    position: relative;
`;

const IFrame = styled.iframe`
    overflow: hidden;
    background: black;
    border: none;
    width: 100%;
    min-height: 200px; // min-height is important for ie11
    height: 40vmin;
    max-height: 400px;

    &:focus {
        outline: none;
    }
`;

const ControlOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        height: 40%;
        fill: white;
        opacity: 0.8;
        filter: drop-shadow(5px 5px 5px #444);
    }
`;

export const YoutubePlayer = ({ videoId, autoPlay, loop, soundIcon }) => {
    const [youtubeApi, setYoutubeApi] = useState(null);
    const [showControlOverlay, setShowControlOverlay] = useState(autoPlay);

    const loadYoutubeApi = () => {
        const player = new window.YT.Player("youtube-player", {});
        setYoutubeApi(player);
    };

    useEffect(() => {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";

        window.onYouTubeIframeAPIReady = loadYoutubeApi;

        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }, []);

    const onControlClick = () => {
        setShowControlOverlay(false);
        youtubeApi.unMute();
    };

    const autoPlayParams = autoPlay ? "autoplay=1&mute=1" : "";
    const loopParams = loop ? `loop=1&playlist=${videoId}` : "";

    return (
        <Wrapper>
            <IFrame
                id="youtube-player"
                allow="autoplay"
                src={`https://www.youtube.com/embed/${videoId}?
              controls=0&playsinline=1&enablejsapi=1&${autoPlayParams}&${loopParams}`}
            />
            {showControlOverlay && youtubeApi && (
                <ControlOverlay onClick={onControlClick}>{soundIcon && <SoundIcon />}</ControlOverlay>
            )}
        </Wrapper>
    );
};
YoutubePlayer.propTypes = {
    videoId: PropTypes.string.isRequired,
    autoPlay: PropTypes.bool.isRequired,
    loop: PropTypes.bool.isRequired,
    soundIcon: PropTypes.bool.isRequired,
};
