import { trackAmplifyEvent, trackFacebookEvent, trackGoogleEvent } from "./common/tracking";

// https://en.wikipedia.org/wiki/UTM_parameters
const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

const amplifySource = "amplify.ooo";

// https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa#Unicode_strings
const toUtf16 = (string) => {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = string.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
};

/**
 * @param {string} event - The event name
 * @param {Object.<string, string>} data - Event data to be sent with
 * @param {string||null} [target] - The name of the object that the event refers
 * @param {Object.<string, string>} [conversionEvents] - A dict that contains conversion event id per platform
 */
export function trackEvent(event, data, target, conversionEvents) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("dashboard")) {
        // Don't track event coming from amplify dashboard
        return;
    }

    let eventName;
    if (target) {
        try {
            eventName = `${event}-${btoa(target)}`;
        } catch (e) {
            // Manage unicode
            eventName = `${event}-utf16-${btoa(toUtf16(target))}`;
        }
    } else {
        eventName = event;
    }

    // We are merging the "standard" utm search parameters with the data dict passed
    // by the caller.
    const utmData = Array.from(urlParams.entries())
        .filter((key_value) => utmParams.includes(key_value[0]))
        .reduce((obj, key_value) => {
            const key = key_value[0];
            const value = key_value[1];

            if (key in obj) {
                // If multiple query params are found then set an array as value
                if (Array.isArray(obj[key])) {
                    obj[key].push(value);
                } else {
                    obj[key] = [obj[key], value];
                }
            } else {
                obj[key] = value;
            }
            return obj;
        }, {});

    let amplifyCampaignId;
    if (utmData["utm_source"] === amplifySource && "utm_campaign" in utmData) {
        amplifyCampaignId = utmData["utm_campaign"];
    } else if (
        Array.isArray(utmData["utm_source"]) &&
        utmData["utm_source"].indexOf(amplifySource) !== -1 &&
        Array.isArray(utmData["utm_campaign"])
    ) {
        const index = utmData["utm_source"].indexOf(amplifySource);
        amplifyCampaignId = utmData["utm_campaign"][index];
    }
    if (!amplifyCampaignId) {
        amplifyCampaignId = "unknown";
    }

    trackAmplifyEvent(eventName, { ...data, ...utmData }, amplifyCampaignId);
    trackFacebookEvent(eventName, { ...data, ...utmData });
    trackGoogleEvent(eventName, window.amplify.googleAdsConversionEventId);
}
