import { getApiUrl } from "../../utils";

export const postContactMe = async (
    slug,
    formName,
    name,
    email = null,
    phone = null,
    comment = null,
    owner,
    language
) => {
    const url = getApiUrl(`/api/landing/${slug}/contact`);
    const params = {
        form_name: formName,
        language,
    };
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    return await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(
            {
                name: name,
                email,
                phone,
                comment,
                owner,
            },
            (key, value) => (value !== null ? value : undefined)
        ),
    });
};
