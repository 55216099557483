import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { ConfigContext, I18nContext } from "../contexts";
import ActionBarPanel, { ActionBarPropTypes } from "../panels/action-bar/panel";
import ContactInfoPanel, { ContactInfoPropTypes } from "../panels/contact-info/panel";
import HeaderPanel from "../panels/header/panel";
import VideoPanel, { VideoPropTypes } from "../panels/video/panel";
import { LeftPaddedCol, PaddedCol, RightPaddedCol, StyledText, TextSection } from "../styles/templates";
import TextMarkdown from "../text-markdown";
import { getTranslation } from "../utils";

export const Education = ({ type, name, logoUrl, description, videoUrls, actions, contactInfo }) => {
    const { i18n } = useContext(I18nContext);
    const { additionalConfig: config } = useContext(ConfigContext);

    return (
        <Row>
            <PaddedCol xs={12}>
                <HeaderPanel name={name} type={type} logoUrl={logoUrl} />
            </PaddedCol>
            <Col xs={12}>
                <VideoPanel videoUrls={videoUrls} />
            </Col>
            <PaddedCol xs={12} className={"mobile"}>
                <ActionBarPanel actions={actions} />
            </PaddedCol>
            <LeftPaddedCol xs={12} sm={7}>
                <StyledText>
                    <TextSection>{getTranslation("common.descriptionTitle", config?.translations, i18n)}</TextSection>
                    <TextMarkdown source={description} />
                </StyledText>
            </LeftPaddedCol>
            <RightPaddedCol xs={12} sm={5}>
                <div className={"desktop"}>
                    <ActionBarPanel actions={actions} />
                </div>
                <TextSection>{getTranslation("contactInfo.title", config?.translations, i18n)}</TextSection>
                <ContactInfoPanel contactInfo={contactInfo} />
            </RightPaddedCol>
        </Row>
    );
};
Education.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    description: PropTypes.string.isRequired,
    videoUrls: VideoPropTypes.isRequired,
    actions: ActionBarPropTypes.isRequired,
    contactInfo: ContactInfoPropTypes.isRequired,
};
Education.defaultProps = {
    logoUrl: null,
};

export default Education;
